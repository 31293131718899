<template>
<v-container grid-list-xl fluid>
    <v-layout row wrap>
        <v-flex lg12>
            <v-card>
                <hpo-filter :headers="headers" :viewName="viewName" v-on:applyFilter="applyFilter" v-on:quickFilter="quickFilter"></hpo-filter>
                <v-data-table fixed-header height="750px"   :headers="headers" :items="orders" :must-sort="true" :options.sync="pagination" :server-items-length="total_rows" :footer-props="items_per_page" :loading="loading" :mobile-breakpoint="100">
                    <template v-slot:header="{ props }"><table-header-filter :headers="props.headers" :viewName="viewName" v-on:applyFilter="applyFilter"></table-header-filter></template>
                    <template v-slot:item="{ item }">
                        <tr>
                            <td>{{ item.ID }}</td>
                            <td>{{ item.Username }}</td>
                            <td class="text-end font-weight-bold">{{ item.Amount | currency}}</td>
                            <td style="width:500px">{{ item.ComplainText }}</td>
                            <td v-bind:style="{ 'color': get_status_color(item.Status, complain_status_list)}">{{ item.Status | display_value(complain_status_list) }}</td>
                            <td>{{ item.CreatedDate | display_date('DD-MM-YYYY HH:mm:ss')}}</td>
                            <td class="text-center">
                                <v-icon medium class="mr-2" @click="gotoDetail(item.ID, item.UID)">fa-external-link-alt</v-icon>
                                <v-icon medium v-if="roleAdmin" @click="cancelItem(item.ID)">fa-times-circle</v-icon>
                            </td>
                        </tr>
                    </template>
                </v-data-table>
            </v-card>
        </v-flex>
    </v-layout>
</v-container>
</template>

<script>
import {
    mapState
} from "vuex";
import configs from "../../configs";
import _ from "lodash";
import Filtering from "@/components/Filtering";
export default {
    components: {
        "hpo-filter": Filtering
    },
    data() {
        let dataType = configs.DATA_TYPE;
        return {
            search: "",
            headers: [{
                    text: "ID",
                    value: "ID",
                    filterable: true,
                    quickSearch: true,
                    dataType: dataType["Number"]
                },
                {
                    text: "Khách hàng",
                    value: "Username",
                    quickSearch: true,
                    filterable: true,
                    dataType: dataType["String"]
                },
                {
                    text: "Số tiền",
                    value: "Amount",
                    align: "end",
                    filterable: true,
                    dataType: dataType["Number"]
                },
                {
                    text: "Nội dung",
                    value: "ComplainText",
                    quickSearch: true,
                    filterable: true,
                    dataType: dataType["String"]
                },                
                {
                    text: "Trạng thái",
                    value: "Status",
                    filterable: true,
                    dataType: dataType["Enum"],
                    values: configs.COMPLAIN_STATUS_LIST
                },
                {
                    text: "Ngày tạo",
                    value: "CreatedDate",
                    filterable: true,
                    dataType: dataType["Date"]
                },
                {
                    text: "Thao tác",
                    value: "",
                    align: "center",
                    sortable: false,
                    filterable: false
                }
            ],
            complain_status_list: configs.COMPLAIN_STATUS_LIST,
            complain_status: configs.COMPLAIN_STATUS,
            pagination: {  ...configs.PAGINATION,  sortBy: ["ID"]  },
            items_per_page: _.cloneDeep(configs.ITEMS_PER_PAGE),
            viewName: "complain_list",
            filterConditions: [],
            quickFilterConditions: [],
        };
    },
    watch: {
        pagination: {
            handler: function (val, oldVal) {
                let filterConditions = this.$store.state.commons.filterConditions;
                this.filterConditions = !_.isEmpty(filterConditions) ? filterConditions[this.viewName] : []
                this.filter_data();
            },
            deep: true
        },
    },
    computed: {
        ...mapState({
            orders: state => state.complain.all.data,
            total_rows: state => state.complain.all.total,
            loading: state => state.complain.loading,
        }),
        roleAdmin() {
            return (this.$store.state.authentication.user.roletype & configs.ROLE_TYPE.Admin) == configs.ROLE_TYPE.Admin;
        }
    },
    methods: {
        filter_data: function () {
            var param = {
                pagination: this.pagination,
                filter: this.filterConditions,
                quickFilter: this.quickFilterConditions
            };
            this.$store.dispatch("complain/getList", param);
        },
        gotoDetail: function (id, uid) {
            let routeData = this.$router.resolve({
                name: "ComplainDetail",
                params: {
                    Pid: id,
                    title: `Chi tiết khiếu nại (#${id})`
                },
                query: {
                    'uid': uid
                }
            });
            window.open(routeData.href, "_blank");
        },
        cancelItem: function (id) {
            window.getApp.$emit('REQUEST_DELETE_DATA', id, `Bạn muốn hủy khiếu nại #${id} ?`);
        },
        applyFilter(filterConditions) {
            let sortBy = this.pagination.sortBy
            let sortDesc = this.pagination.sortDesc
            this.pagination = configs.PAGINATION;
            this.pagination.sortBy = sortBy;
            this.pagination.sortDesc = sortDesc;
            this.filterConditions = filterConditions;
            this.filter_data();
        },
        quickFilter(filterConditions) {
            this.quickFilterConditions = filterConditions;
            this.filter_data();
        },
        get_status_color(val, enums) {
            return this.$options.filters.display_color(val, enums);
        },
    },
    mounted() {
        this.$root.$on("REQUEST_REFRESH_DATA", this.filter_data);
        this.$root.$on("CONFIRMED_REMOVE_DATA", (id) => {
            var updateComplain = {
                "ID": id,
                "Status": this.complain_status.Cancel
            }
            this.$store.dispatch("complain/setDetail", updateComplain);
            setTimeout(this.filter_data, 200);
        });
    },
    beforeDestroy() {
        this.$root.$off("REQUEST_REFRESH_DATA", this.filter_data);
        this.$root.$off("CONFIRMED_REMOVE_DATA");
    }
};
</script>
